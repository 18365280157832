import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'VOTE',
    lpAddresses: {
      97: '',
      56: '0x634D10911a59651E8344691c35d6a4545f7934A1',
    },
    tokenSymbol: 'SYRUP',
    tokenAddresses: {
      97: '',
      56: '0x634D10911a59651E8344691c35d6a4545f7934A1',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 1,
    lpSymbol: 'VOTE-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xFcCf9861E67e696E2FCc3F45847EA424220f7Aa8',   // lp address token-bnb
    },
    tokenSymbol: 'VOTE',
    tokenAddresses: {
      97: '',
      56: '0x634D10911a59651E8344691c35d6a4545f7934A1', // token address
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

]

export default farms
